@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply bg-gray-800;
  }
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: rgba(42, 49, 61, 0.4);
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #5e5e5e;
  border-radius: 100vh;
  border: 2px solid rgba(42, 49, 61, 0.4);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #919191;
}
